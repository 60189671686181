import { Color } from '~/src/components/shared/Notification/types'

export const NotificationDisplayTier = {
  RISK: 'AT RISK',
  CRITICAL: 'CRITICAL',
  RESOLVED: 'RESOLVED',
}

// Will remove this in a follow up ticket after updating the NotificationType in the BE:
// https://app.asana.com/0/1207720206514961/1208048215005834/f
export const NotificationDisplayViolation = {
  HOS_VIOLATION: 'HoS violation',
  LEG_NOT_STARTED: 'Leg not started',
  ONE_HOUR_DETENTION: 'Two-hour dwell', // TODO: Rename symbol
  DETENTION: 'Two-hour dwell',
}

export const colorsMapping: { [key: string]: Color } = {
  CRITICAL: 'red',
  RISK: 'yellow',
}

export const NOTIFICATION_COLOR_CLASS_NAME = {
  red: 'text-red-600 font-extrabold',
  yellow: 'text-yellow-600 font-extrabold',
}
